import React, { useEffect, useRef } from "react"
import axios from "axios"
import { Input, Tooltip } from "antd"

/* BASE URL */
export const siteURL = "https://sales.hanataba2020.com"
// export const siteURL = "http://52.194.177.152"
// export const siteURL = "http://localhost:8080"
export const baseURL = siteURL + "/api"

/* REDIRECT URL */
export const loginURL = "/login"
export const rootURL = "/home"

/* BACKEND API URLS */
export const apiCheckSession = baseURL + "/sessusers"
export const apiLogin = baseURL + "/login"
export const apiLogout = baseURL + "/logout"
export const apiDaily = baseURL + "/m/daily"
export const apiWeekly = baseURL + "/m/weekly"
export const apiMonthly = baseURL + "/m/monthly"
export const apiYearly = baseURL + "/m/yearly"
export const apiDownloads = baseURL + "/m/dls"

/* ROUTES */
export const loginRoute = "/login"
export const homeRoute = "/home"
export const notFoundRoute = "/not-found"

/* ALERT MESSAGES */
export const errorLoginMismatchMsg = "CODEまたはパスワードが間違っています"
export const errorSystemMsg = "システムエラー"
export const errorSessionMsg = "もう一度ログインしてください"
export const errorPermissionMsg = "許可が足りない"
export const error404Msg =
  "申し訳ありませんが、アクセスしたページは存在しません。"
export const successLoginMsg = "ログイン成功"
export const successLogoutMsg = "ログアウト成功"
export const successCreateMsg = "登録成功"
export const successUpdateMsg = "変更成功"
export const successSaveMsg = "保存成功"
export const successDeleteMsg = "削除成功"
export const successSentMsg = "送信成功"

/* AXIOS CONFIG */
export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: baseURL,
})

/* CHECK MOUNTED REACT */
export function useIsMountedRef() {
  const isMountedRef = useRef(null)

  useEffect(() => {
    isMountedRef.current = true
    return () => (isMountedRef.current = false)
  })

  return isMountedRef
}

/* PRICE NUMBER INPUT */
function formatNumber(value) {
  value += ""
  const list = value.split(".")
  const prefix = list[0].charAt(0) === "-" ? "-" : ""
  let num = prefix ? list[0].slice(1) : list[0]
  let result = ""

  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`
    num = num.slice(0, num.length - 3)
  }

  if (num) {
    result = num + result
  }

  return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`
}

export class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target
    const reg = /^-?\d*(\.\d*)?$/

    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      this.props.onChange(value)
    }
  }

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props
    let valueTemp = value
    if (value.charAt(value.length - 1) === "." || value === "-") {
      valueTemp = value.slice(0, -1)
    }
    onChange(valueTemp.replace(/0*(\d+)/, "$1"))
    if (onBlur) {
      onBlur()
    }
  }

  render() {
    const { value } = this.props
    const title = value ? (
      <span className="numeric-input-title">
        {value !== "-" ? formatNumber(value) : "-"}
      </span>
    ) : (
      ""
    )

    return (
      <Tooltip
        trigger={["focus"]}
        title={title}
        placement="topLeft"
        overlayClassName="numeric-input"
      >
        <Input
          {...this.props}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder=""
          maxLength={25}
          step={100}
          pattern="[0-9]*"
          onPressEnter={e => e.preventDefault()}
        />
      </Tooltip>
    )
  }
}
