import React from "react"
import { withRouter } from "react-router-dom"
import { Menu, message } from "antd"
import { MenuOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons"
import * as Commons from "common/common"

const Topbar = props => {
  const { isHamburger, collapseToggle, history } = props

  const logout = () => {
    Commons.axiosInstance
      .get(Commons.apiLogout)
      .then(response => {
        if (response.status === 200) {
          message.success(Commons.successLogoutMsg)
          history.push(Commons.loginURL)
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
  }

  const handleClick = event => {
    switch (event.key) {
      case "logout":
        logout()
        break
      case "menu":
        collapseToggle()
        break
      default:
        break
    }
  }

  return (
    <div>
      {isHamburger ? (
        <Menu mode="horizontal" onClick={handleClick} selectable={false}>
          <Menu.Item
            className="float-left"
            key="menu"
            icon={<MenuOutlined style={{ fontSize: 18 }} />}
          ></Menu.Item>
          <Menu.Item
            className="float-right"
            style={{ fontSize: 12 }}
            key="logout"
            icon={<LogoutOutlined style={{ fontSize: 14 }} />}
            danger
          >
            ログアウト
          </Menu.Item>
          <Menu.Item
            className="float-right"
            style={{ fontSize: 12 }}
            key="profile"
            icon={<UserOutlined style={{ fontSize: 14 }} />}
          >
            管理者
          </Menu.Item>
        </Menu>
      ) : (
        <Menu mode="horizontal" onClick={handleClick} selectable={false}>
          <Menu.Item
            className="float-right"
            key="logout"
            icon={<LogoutOutlined style={{ fontSize: 18 }} />}
            danger
          >
            ログアウト
          </Menu.Item>
          <Menu.Item
            className="float-right"
            key="profile"
            icon={<UserOutlined style={{ fontSize: 18 }} />}
          >
            管理者
          </Menu.Item>
        </Menu>
      )}
    </div>
  )
}

export default withRouter(Topbar)
