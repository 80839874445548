import React from "react"
import { Button, Divider, Menu, Typography } from "antd"
import {
  HomeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons"
import { withRouter, useLocation } from "react-router-dom"
import styled from "styled-components"
import * as Commons from "common/common"

const StyledMenu = styled(Menu)`
  border: none;
`

const Sidebar = props => {
  const { history, isHamburger, isCollapsed, collapseToggle } = props
  const location = useLocation()
  const { Text } = Typography

  const handleClick = event => {
    switch (event.key) {
      case Commons.homeRoute:
        history.push(Commons.homeRoute)
        break
      case "collapse":
        collapseToggle()
        break
      default:
        break
    }

    if (isHamburger) collapseToggle()
  }

  return (
    <div
      className="flex flex-col h-full"
      style={{ overflowX: "hidden", overflowY: "auto" }}
    >
      <div className="flex" style={{ marginTop: "1rem" }}>
        <img src="logo.svg" alt="" className="mx-auto w-3/4" />
      </div>
      <div className="relative flex text-center px-1">
        <Text style={{ fontSize: 16, marginTop: 20 }} className="mx-auto">
          売上システム
        </Text>
      </div>
      <Divider />
      <div className="flex-grow flex-shrink">
        <StyledMenu
          onClick={handleClick}
          defaultSelectedKeys={[location.pathname]}
          mode="inline"
        >
          <Menu.Item
            key={Commons.homeRoute}
            icon={
              <HomeOutlined
                style={{
                  marginRight: 25,
                  fontSize: 22,
                }}
              />
            }
          >
            売上管理
          </Menu.Item>
        </StyledMenu>
      </div>
      <Divider style={{ margin: 0 }} />
      <Button className="p-0" onClick={collapseToggle}>
        {isCollapsed ? (
          <MenuUnfoldOutlined
            style={{
              fontSize: 22,
            }}
          />
        ) : (
          <MenuFoldOutlined
            style={{
              fontSize: 22,
            }}
          />
        )}
      </Button>
    </div>
  )
}

export default withRouter(Sidebar)
