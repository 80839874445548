import React, { useState, useEffect, useCallback, useRef } from "react"
import { withRouter } from "react-router-dom"
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  List,
  Form,
  message,
  Modal,
  Table,
  Tooltip,
  Radio,
  Row,
} from "antd"
import {
  FilePdfOutlined,
  FileTextOutlined,
  ContainerOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons"
import { Bar, Doughnut } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import styled from "styled-components"
import moment from "moment"
import "moment/locale/ja"
import { CSVLink } from "react-csv"
import jsPDF from "jspdf"
import "jspdf-autotable"
import * as KosugiRegular from "common/KosugiRegular"
import * as Commons from "common/common"

moment.locale("ja")

const CustomTable = styled(Table)`
  .ant-table {
    white-space: pre-wrap;
  }
`

const CustomList = styled(List)`
  .ant-list-header {
    background-color: #3dbaeb;
  }
`

const Home = props => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const isMountedRef = Commons.useIsMountedRef()
  const [form] = Form.useForm()
  const dailyPointChartRef = useRef(null)
  const dailyGuestMatchingChartRef = useRef(null)
  const dailyCastMatchingChartRef = useRef(null)
  const dailyGuestRankingChartRef = useRef(null)
  const dailyCastRankingChartRef = useRef(null)
  const { RangePicker } = DatePicker

  const MARGIN = 10

  const FILTER_DURATION_TYPE = [
    {
      key: "DAY",
      value: "日次",
    },
    {
      key: "WEEK",
      value: "週次",
    },
    {
      key: "MONTH",
      value: "月次",
    },
    {
      key: "YEAR",
      value: "年次",
    },
    // {
    //   key: "CUSTOM",
    //   value: "カスタム",
    // },
  ]

  const CSV_HEADER = [
    "日付",
    "利用件数",
    "利用人数",
    "利用時間",
    "利益ポイント",
    "利益率",
    "利用ポイント",
    "報酬ポイント",
    "ポイント購入数",
    "ゲスト付与ポイント",
    "キャスト付与ポイント",
    "その他ご利用ポイント",
    "ゲストポイント残高",
    "キャストポイント残高",
  ]

  const COLORS = {
    redH: "rgba(102,38,33,0.8)",
    redL: "rgba(121,48,42,0.8)",
    orangeH: "rgba(233,87,62,0.8)",
    orangeL: "rgba(251,110,82,0.8)",
    yellowH: "rgba(246,187,69,0.8)",
    yellowL: "rgba(255,206,85,0.8)",
    greenH: "rgba(140,192,81,0.8)",
    greenL: "rgba(160,212,104,0.8)",
    aquaH: "rgba(59,174,218,0.8)",
    aquaL: "rgba(79,192,234,0.8)",
    purpleH: "rgba(150,123,220,0.8)",
    purpleL: "rgba(172,146,235,0.8)",
    mintH: "rgba(54,188,155,0.8)",
    mintL: "rgba(72,207,174,0.8)",
    pinkH: "rgba(216,112,173,0.8)",
    pinkL: "rgba(235,136,190,0.8)",
  }

  const [downloadsData, setDownloadsData] = useState([])
  const [dailyPointsData, setDailyPointsData] = useState([])
  const [dailyRankingsData, setDailyRankingsData] = useState([])
  const [weeklyPointsData, setWeeklyPointsData] = useState([])
  const [weeklyRankingsData, setWeeklyRankingsData] = useState([])
  const [monthlyPointsData, setMonthlyPointsData] = useState([])
  const [monthlyRankingsData, setMonthlyRankingsData] = useState([])
  const [yearlyPointsData, setYearlyPointsData] = useState([])
  const [yearlyRankingsData, setYearlyRankingsData] = useState([])
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false)
  const [selectedDuration, setSelectedDuration] = useState(
    FILTER_DURATION_TYPE[0].key,
  )

  const pointDailyOptions = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: "start",
        backgroundColor: function(context) {
          return context.dataset.backgroundColor
        },
        borderColor: "#FFF",
        borderRadius: 25,
        borderWidth: 2,
        rotation: 0,
        color: "#FFF",
        display: "true",
        formatter: function(value, context) {
          if (value === 0) return null

          let dataset = context.dataset
          let count = dataset.data.length
          let sum = 0

          for (let i = 0; i < count; i++) {
            sum += dataset.data[i]
          }

          return Math.round((100 * value) / sum) + "%"
        },
        font: {
          family:
            "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          weight: "600",
        },
        padding: 6,
      },
    },
    title: {
      display: true,
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontSize: 16,
      fontStyle: "600",
      text: ["利益ポイント"],
    },
    tooltips: {
      bodyFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      callbacks: {
        label: (item, data) => {
          return data.labels[item.index] || ""
        },
      },
    },
  }

  const pointWeeklyOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "利益ポイント",
      fontSize: 16,
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontStyle: "600",
    },
    plugins: {
      datalabels: {
        font: {
          family:
            "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          weight: "600",
        },
        color: "#FFF",
        rotation: 0,
        textStrokeColor: function(context) {
          return context.dataset.backgroundColor
        },
        textStrokeWidth: 3,
        formatter: function(value, context) {
          if (value === 0) return ""
          return value.toLocaleString() + "P"
        },
      },
    },
    scales: {
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
              return value.toLocaleString() + "P"
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            fontFamily:
              "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
            fontStyle: "500",
          },
        },
      ],
    },
    tooltips: {
      titleFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      bodyFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      callbacks: {
        label: (item, data) => {
          let label = data.datasets[item.datasetIndex].label || ""

          if (label) {
            label += ": "
          }

          label += (Math.round(item.yLabel * 100) / 100).toLocaleString() + "P"

          return label
        },
      },
    },
  }

  const pointMonthlyOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "利益ポイント",
      fontSize: 16,
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontStyle: "600",
    },
    plugins: {
      datalabels: {
        font: {
          family:
            "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          weight: "600",
        },
        color: "#FFF",
        rotation: -90,
        textStrokeColor: function(context) {
          return context.dataset.backgroundColor
        },
        textStrokeWidth: 3,
        formatter: function(value, context) {
          if (value === 0) return ""
          return value.toLocaleString() + "P"
        },
      },
    },
    scales: {
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
              return value.toLocaleString() + "P"
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            fontFamily:
              "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
            fontStyle: "500",
          },
        },
      ],
    },
    tooltips: {
      titleFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      bodyFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      callbacks: {
        label: (item, data) => {
          let label = data.datasets[item.datasetIndex].label || ""

          if (label) {
            label += ": "
          }

          label += (Math.round(item.yLabel * 100) / 100).toLocaleString() + "P"

          return label
        },
      },
    },
  }

  const guestMatchingDoughnutOptions = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: "start",
        backgroundColor: function(context) {
          return context.dataset.backgroundColor
        },
        borderColor: "#FFF",
        borderRadius: 25,
        borderWidth: 2,
        rotation: 0,
        color: "#FFF",
        display: true,
        formatter: function(value, context) {
          if (value === 0) return null

          let dataset = context.dataset
          let count = dataset.data.length
          let sum = 0

          for (let i = 0; i < count; i++) {
            sum += dataset.data[i]
          }

          return Math.round((100 * value) / sum) + "%"
        },
        font: {
          family:
            "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          weight: "600",
        },
        padding: 6,
      },
    },
    title: {
      display: true,
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontSize: 16,
      fontStyle: "600",
      text: [
        "ゲスト利用ポイント",
        dailyPointsData && dailyPointsData.totalCur
          ? "全" +
            (
              (dailyPointsData.totalCur.cast?.basic || 0) +
              (dailyPointsData.totalCur.cast?.extension || 0) +
              (dailyPointsData.totalCur.cast?.priority || 0) +
              (dailyPointsData.totalCur.cast?.night || 0)
            ).toLocaleString() +
            "P"
          : "",
      ],
    },
    tooltips: {
      bodyFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      callbacks: {
        label: (item, data) => {
          return data.labels[item.index] || ""
        },
      },
    },
  }

  const castMatchingDoughnutOptions = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: "start",
        backgroundColor: function(context) {
          return context.dataset.backgroundColor
        },
        borderColor: "#FFF",
        borderRadius: 25,
        borderWidth: 2,
        rotation: 0,
        color: "#FFF",
        display: true,
        formatter: function(value, context) {
          if (value === 0) return null

          let dataset = context.dataset
          let count = dataset.data.length
          let sum = 0

          for (let i = 0; i < count; i++) {
            sum += dataset.data[i]
          }

          return Math.round((100 * value) / sum) + "%"
        },
        font: {
          family:
            "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          weight: "600",
        },
        padding: 6,
      },
    },
    title: {
      display: true,
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontSize: 16,
      fontStyle: "600",
      text: [
        "キャスト報酬ポイント",
        dailyPointsData && dailyPointsData.totalCur
          ? "全" +
            (
              ((dailyPointsData.totalCur.cast?.basic || 0) +
                (dailyPointsData.totalCur.cast?.extension || 0) +
                (dailyPointsData.totalCur.cast?.priority || 0) +
                (dailyPointsData.totalCur.cast?.night || 0)) *
              0.7
            ).toLocaleString() +
            "P"
          : "",
      ],
    },
    tooltips: {
      bodyFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      callbacks: {
        label: (item, data) => {
          return data.labels[item.index] || ""
        },
      },
    },
  }

  const guestRankingOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "ゲストランキング",
      fontSize: 16,
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontStyle: "600",
    },
    plugins: {
      datalabels: {
        font: {
          family:
            "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          weight: "600",
        },
        color: "#FFF",
        textStrokeColor: function(context) {
          return context.dataset.backgroundColor
        },
        textStrokeWidth: 3,
        rotation: 0,
        anchor: "end",
        align: "start",
        formatter: function(value, context) {
          return value.toLocaleString() + "P"
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
              return value.toLocaleString() + "P"
            },
            // max: weeklyRankingsData.guests
            //   ? Math.max(
            //       ...weeklyRankingsData.guests.map(guest =>
            //         Math.abs(guest.sumPoints || 0),
            //       ),
            //     ) * 1.1
            //   : 0,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontFamily:
              "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
            fontStyle: "500",
          },
        },
      ],
    },
    tooltips: {
      titleFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      bodyFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      callbacks: {
        label: (item, data) => {
          let label = data.datasets[item.datasetIndex].label || ""

          if (label) {
            label += ": "
          }

          label += (Math.round(item.yLabel * 100) / 100).toLocaleString() + "P"

          return label
        },
      },
    },
  }

  const castRankingOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "キャストランキング",
      fontSize: 16,
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontStyle: "600",
    },
    plugins: {
      datalabels: {
        font: {
          family:
            "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          weight: "600",
        },
        color: "#FFF",
        textStrokeColor: function(context) {
          return context.dataset.backgroundColor
        },
        textStrokeWidth: 3,
        rotation: 0,
        anchor: "end",
        align: "start",
        formatter: function(value, context) {
          return value.toLocaleString() + "P"
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
              return value.toLocaleString() + "P"
            },
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontFamily:
              "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
            fontStyle: "500",
          },
        },
      ],
    },
    tooltips: {
      titleFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      bodyFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      callbacks: {
        label: (item, data) => {
          let label = data.datasets[item.datasetIndex].label || ""

          if (label) {
            label += ": "
          }

          label += (Math.round(item.yLabel * 100) / 100).toLocaleString() + "P"

          return label
        },
      },
    },
  }

  const guestMatchingWeeklyBarOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "ゲスト利用ポイント",
      fontSize: 16,
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontStyle: "600",
    },
    plugins: {
      datalabels: {
        font: {
          family:
            "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          weight: "600",
        },
        display: true,
        clip: true,
        color: "#FFF",
        rotation: 0,
        textStrokeWidth: 3,
        textStrokeColor: function(context) {
          return context.dataset.backgroundColor
        },
        formatter: function(value, context) {
          if (value === 0) return ""
          return value.toLocaleString() + "P"
        },
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
              return value.toLocaleString() + "P"
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            fontFamily:
              "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
            fontStyle: "500",
          },
        },
      ],
    },
    tooltips: {
      titleFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      bodyFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      callbacks: {
        label: (item, data) => {
          let label = data.datasets[item.datasetIndex].label || ""

          if (label) {
            label += ": "
          }

          label += (Math.round(item.yLabel * 100) / 100).toLocaleString() + "P"

          return label
        },
      },
    },
  }

  const guestMatchingMonthlyBarOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "ゲスト利用ポイント",
      fontSize: 16,
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontStyle: "600",
    },
    plugins: {
      datalabels: {
        font: {
          family:
            "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          weight: "600",
        },
        display: true,
        clip: true,
        color: "#FFF",
        rotation: 0,
        textStrokeWidth: 3,
        formatter: function(value, context) {
          if (value === 0) return ""
          return value.toLocaleString() + "P"
        },
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
              return value.toLocaleString() + "P"
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            fontFamily:
              "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
            fontStyle: "500",
          },
        },
      ],
    },
    tooltips: {
      titleFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      bodyFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      callbacks: {
        label: (item, data) => {
          let label = data.datasets[item.datasetIndex].label || ""

          if (label) {
            label += ": "
          }

          label += (Math.round(item.yLabel * 100) / 100).toLocaleString() + "P"

          return label
        },
      },
    },
  }

  const castMatchingWeeklyBarOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "キャスト報酬ポイント",
      fontSize: 16,
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontStyle: "600",
    },
    plugins: {
      datalabels: {
        font: {
          family:
            "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          weight: "600",
        },
        display: true,
        clip: true,
        color: "#FFF",
        rotation: 0,
        textStrokeWidth: 3,
        textStrokeColor: function(context) {
          return context.dataset.backgroundColor
        },
        formatter: function(value, context) {
          if (value === 0) return ""
          return value.toLocaleString() + "P"
        },
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
              return value.toLocaleString() + "P"
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            fontFamily:
              "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
            fontStyle: "500",
          },
        },
      ],
    },
    tooltips: {
      titleFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      bodyFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      callbacks: {
        label: (item, data) => {
          let label = data.datasets[item.datasetIndex].label || ""

          if (label) {
            label += ": "
          }

          label += (Math.round(item.yLabel * 100) / 100).toLocaleString() + "P"

          return label
        },
      },
    },
  }

  const castMatchingMonthlyBarOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "キャスト報酬ポイント",
      fontSize: 16,
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontStyle: "600",
    },
    plugins: {
      datalabels: {
        font: {
          family:
            "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          weight: "600",
        },
        display: true,
        clip: true,
        color: "#FFF",
        rotation: 0,
        textStrokeWidth: 3,
        formatter: function(value, context) {
          if (value === 0) return ""
          return value.toLocaleString() + "P"
        },
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
              return value.toLocaleString() + "P"
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            fontFamily:
              "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
            fontStyle: "500",
          },
        },
      ],
    },
    tooltips: {
      titleFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      bodyFontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      callbacks: {
        label: (item, data) => {
          let label = data.datasets[item.datasetIndex].label || ""

          if (label) {
            label += ": "
          }

          label += (Math.round(item.yLabel * 100) / 100).toLocaleString() + "P"

          return label
        },
      },
    },
  }

  const guestMatchingDailyLegend = {
    labels: {
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontSize: 14,
      fontStyle: "500",
    },
  }

  const castMatchingDailyLegend = {
    labels: {
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontSize: 14,
      fontStyle: "500",
    },
  }

  const guestRankingLegend = {
    labels: {
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontSize: 14,
      fontStyle: "500",
    },
  }

  const castRankingLegend = {
    labels: {
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontSize: 14,
      fontStyle: "500",
    },
  }

  const guestMatchingLegend = {
    labels: {
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontSize: 14,
      fontStyle: "500",
    },
  }

  const castMatchingLegend = {
    labels: {
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontSize: 14,
      fontStyle: "500",
    },
  }

  const pointLegend = {
    labels: {
      fontFamily:
        "Yu Gothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
      fontSize: 14,
      fontStyle: "500",
    },
  }

  const columns = [
    {
      key: "date",
      title: "日付",
      dataIndex: "date",
      align: "center",
    },
    {
      key: "total",
      title: "合計",
      children: [
        {
          key: "totalUsage",
          title: "利用\n件数",
          dataIndex: "totalUsage",
          align: "center",
        },
        {
          key: "totalUser",
          title: "利用\n人数",
          dataIndex: "totalUser",
          align: "center",
        },
        {
          key: "totalUsageTime",
          title: "利用\n時間",
          dataIndex: "totalUsageTime",
          align: "center",
        },
        {
          key: "totalProfitPoint",
          title: (
            <>
              {"利益"}
              <Tooltip
                placement="bottom"
                trigger={["hover", "click"]}
                title="(利用ポイント - 報酬ポイント)"
              >
                <Button
                  size="small"
                  type="link"
                  shape="circle"
                  icon={<QuestionCircleOutlined />}
                />
              </Tooltip>
              {"\nポイント"}
            </>
          ),
          dataIndex: "totalProfitPoint",
          align: "center",
        },
        {
          key: "totalProfitRate",
          title: (
            <>
              {"利益率"}
              <Tooltip
                placement="bottom"
                trigger={["hover", "click"]}
                title="(利益ポイント / 利用ポイント) * 100"
              >
                <Button
                  size="small"
                  type="link"
                  shape="circle"
                  icon={<QuestionCircleOutlined />}
                />
              </Tooltip>
            </>
          ),
          dataIndex: "totalProfitRate",
          align: "center",
        },
      ],
    },
    {
      key: "matchingPoint",
      title: "利益",
      children: [
        {
          key: "matchUsagePoint",
          title: "利用\nポイント",
          dataIndex: "matchUsagePoint",
          align: "center",
        },
        {
          key: "matchRewardPoint",
          title: (
            <>
              {"報酬"}
              <Tooltip
                placement="bottom"
                trigger={["hover", "click"]}
                title="(基本料, 延長料, セレクト料, 深夜料) * 0.7"
              >
                <Button
                  size="small"
                  type="link"
                  shape="circle"
                  icon={<QuestionCircleOutlined />}
                />
              </Tooltip>
              {"\nポイント"}
            </>
          ),
          dataIndex: "matchRewardPoint",
          align: "center",
        },
        {
          key: "matchPurchasePoint",
          title: "ポイント\n購入数",
          dataIndex: "matchPurchasePoint",
          align: "center",
        },
        {
          key: "matchEarnPoint",
          title: "付与ポイント",
          children: [
            {
              key: "matchGuestEarnPoint",
              title: "ゲスト",
              dataIndex: "matchGuestEarnPoint",
              align: "center",
            },
            {
              key: "matchCastEarnPoint",
              title: "キャスト",
              dataIndex: "matchCastEarnPoint",
              align: "center",
            },
          ],
        },
      ],
    },
    {
      key: "profitPoint",
      title: "その他\nご利用\nポイント",
      dataIndex: "profitPoint",
      align: "center",
    },
    {
      key: "guestPointBalance",
      title: (
        <>
          {"ゲスト"}
          <Tooltip
            placement="bottom"
            trigger={["hover", "click"]}
            title="(全ポイント購入数 - 全利用ポイント)"
          >
            <Button
              size="small"
              type="link"
              shape="circle"
              icon={<QuestionCircleOutlined />}
            />
          </Tooltip>
          {"\nポイント\n残高"}
        </>
      ),
      dataIndex: "guestPointBalance",
      align: "center",
    },
    {
      key: "castPointBalance",
      title: (
        <>
          {"キャスト"}
          <Tooltip
            placement="bottom"
            trigger={["hover", "click"]}
            title="(全報酬ポイント - 全支払ポイント)"
          >
            <Button
              size="small"
              type="link"
              shape="circle"
              icon={<QuestionCircleOutlined />}
            />
          </Tooltip>
          {"\nポイント\n残高"}
        </>
      ),
      dataIndex: "castPointBalance",
      align: "center",
    },
  ]

  const fetchDailyData = useCallback(
    selectedDate => {
      if (isMountedRef.current) {
        showLoadingPageSpin()
      }

      const params = {
        params: {
          date:
            moment(selectedDate).format("YYYY-MM-DD") ||
            moment().format("YYYY-MM-DD"),
        },
      }

      Commons.axiosInstance
        .get(Commons.apiDaily + "/points", params)
        .then(response => {
          if (isMountedRef) {
            setDailyPointsData(response.data || {})
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin(false)
          }
        })

      Commons.axiosInstance
        .get(Commons.apiDaily + "/ranking", params)
        .then(response => {
          if (isMountedRef) {
            setDailyRankingsData(response.data || [])
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin(false)
          }
        })
    },
    [history, isMountedRef, showLoadingPageSpin, hideLoadingPageSpin],
  )

  const fetchWeeklyData = useCallback(
    selectedDate => {
      if (isMountedRef.current) {
        showLoadingPageSpin()
      }

      const params = {
        params: {
          year: moment(selectedDate).year() || moment().year(),
          week: moment(selectedDate).week() || moment().week(),
        },
      }

      Commons.axiosInstance
        .get(Commons.apiWeekly + "/points", params)
        .then(response => {
          if (isMountedRef) {
            setWeeklyPointsData(
              response.data
                ? {
                    ...response.data,
                    year: moment(selectedDate).year() || moment().year(),
                    week: moment(selectedDate).week() || moment().week(),
                  }
                : {},
            )
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin(false)
          }
        })

      Commons.axiosInstance
        .get(Commons.apiWeekly + "/ranking", params)
        .then(response => {
          if (isMountedRef) {
            setWeeklyRankingsData(response.data || [])
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin(false)
          }
        })
    },
    [history, isMountedRef, showLoadingPageSpin, hideLoadingPageSpin],
  )

  const fetchMonthlyData = useCallback(
    selectedDate => {
      if (isMountedRef.current) {
        showLoadingPageSpin()
      }

      const params = {
        params: {
          date:
            moment(selectedDate).format("YYYY-MM") ||
            moment().format("YYYY-MM"),
        },
      }

      Commons.axiosInstance
        .get(Commons.apiMonthly + "/points", params)
        .then(response => {
          if (isMountedRef) {
            setMonthlyPointsData(
              response.data
                ? {
                    ...response.data,
                    year: moment(selectedDate).year() || moment().year(),
                    month:
                      (moment(selectedDate).month() || moment().month()) + 1,
                  }
                : {},
            )
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin(false)
          }
        })

      Commons.axiosInstance
        .get(Commons.apiMonthly + "/ranking", params)
        .then(response => {
          if (isMountedRef) {
            setMonthlyRankingsData(response.data || [])
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin(false)
          }
        })
    },
    [history, isMountedRef, showLoadingPageSpin, hideLoadingPageSpin],
  )

  const fetchYearlyData = useCallback(
    selectedDate => {
      if (isMountedRef.current) {
        showLoadingPageSpin()
      }

      const params = {
        params: {
          date: moment(selectedDate).year() || moment().year(),
        },
      }

      Commons.axiosInstance
        .get(Commons.apiYearly + "/points", params)
        .then(response => {
          if (isMountedRef) {
            setYearlyPointsData(
              response.data
                ? {
                    ...response.data,
                    year: moment(selectedDate).year() || moment().year(),
                  }
                : {},
            )
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin(false)
          }
        })

      Commons.axiosInstance
        .get(Commons.apiYearly + "/ranking", params)
        .then(response => {
          if (isMountedRef) {
            setYearlyRankingsData(response.data || [])
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin(false)
          }
        })
    },
    [history, isMountedRef, showLoadingPageSpin, hideLoadingPageSpin],
  )

  const fetchDownloadData = useCallback(() => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    Commons.axiosInstance
      .get(Commons.apiDownloads)
      .then(response => {
        if (isMountedRef) {
          setDownloadsData(response.data || [])
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin(false)
        }
      })
  }, [history, isMountedRef, showLoadingPageSpin, hideLoadingPageSpin])

  const handleDownloadLog = () => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    const postData = {
      timestamp: moment().toISOString(true),
    }

    Commons.axiosInstance
      .post(Commons.apiDownloads, postData)
      .then(response => {
        if (isMountedRef && response.data) {
          setDownloadsData(response.data)
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin(false)
        }
      })
  }

  const handleFilter = data => {
    switch (selectedDuration) {
      case FILTER_DURATION_TYPE[0].key:
        fetchDailyData(data.dateDurationFilter || moment())
        break
      case FILTER_DURATION_TYPE[1].key:
        fetchWeeklyData(data.weekDurationFilter || moment())
        break
      case FILTER_DURATION_TYPE[2].key:
        fetchMonthlyData(data.monthDurationFilter || moment())
        break
      case FILTER_DURATION_TYPE[3].key:
        fetchYearlyData(data.yearDurationFilter || moment())
        break
      default:
        break
    }
  }

  useEffect(fetchDailyData, [])
  useEffect(fetchWeeklyData, [])
  useEffect(fetchMonthlyData, [])
  useEffect(fetchYearlyData, [])
  useEffect(fetchDownloadData, [])

  const pointDailyData = {
    labels:
      dailyPointsData && dailyPointsData.totalCur
        ? [
            "ポイント購入数 - " +
              (dailyPointsData.totalCur.guest?.buy || "0").toLocaleString() +
              "P",
            "付与ポイント - " +
              (dailyPointsData.totalCur.guest?.earn || "0").toLocaleString() +
              "P",
            "利用ポイント - " +
              (
                Math.abs(dailyPointsData.totalCur.guest?.spend) || "0"
              ).toLocaleString() +
              "P",
          ]
        : [],
    datasets: [
      {
        data:
          dailyPointsData && dailyPointsData.totalCur
            ? [
                dailyPointsData.totalCur.guest?.buy || 0,
                dailyPointsData.totalCur.guest?.earn || 0,
                Math.abs(dailyPointsData.totalCur.guest?.spend) || 0,
              ]
            : [],
        backgroundColor: [COLORS.greenH, COLORS.mintH, COLORS.aquaH],
        borderWidth: 1,
      },
    ],
  }

  const pointWeeklyData = {
    labels: weeklyPointsData.data
      ? weeklyPointsData.data.map(d =>
          d.dt ? moment(d.dt, "YYYY-MM-DD").format("D(ddd)") : "",
        )
      : [],
    datasets: [
      {
        type: "bar",
        label: "ポイント購入数",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(d => d.guest?.buy || 0)
          : [],
        backgroundColor: COLORS.greenH,
        borderColor: COLORS.greenL,
        borderWidth: 1,
        stack: "STACK 1",
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
      {
        type: "bar",
        label: "報酬ポイント",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(
              d =>
                ((d.cast?.basic || 0) +
                  (d.cast?.extension || 0) +
                  (d.cast?.priority || 0) +
                  (d.cast?.night || 0)) *
                0.7,
            )
          : [],
        backgroundColor: COLORS.pinkH,
        borderColor: COLORS.pinkL,
        borderWidth: 1,
        stack: "STACK 2",
        datalabels: {
          align: "center",
          anchor: "center",
        },
      },
      {
        type: "bar",
        label: "利用ポイント",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(d => d.guest?.spend || 0)
          : [],
        backgroundColor: COLORS.aquaH,
        borderColor: COLORS.aquaL,
        borderWidth: 1,
        stack: "STACK 2",
        datalabels: {
          align: "center",
          anchor: "center",
        },
      },
    ],
  }

  const pointMonthlyData = {
    labels: monthlyPointsData.data
      ? monthlyPointsData.data.map(d =>
          d.dt ? moment(d.dt, "YYYY-MM-DD").format("D") : "",
        )
      : [],
    datasets: [
      {
        type: "bar",
        label: "ポイント購入数",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(d => d.guest?.buy || 0)
          : [],
        backgroundColor: COLORS.greenH,
        borderColor: COLORS.greenL,
        borderWidth: 1,
        stack: "STACK 1",
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
      {
        type: "bar",
        label: "報酬ポイント",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(
              d =>
                ((d.cast?.basic || 0) +
                  (d.cast?.extension || 0) +
                  (d.cast?.priority || 0) +
                  (d.cast?.night || 0)) *
                0.7,
            )
          : [],
        backgroundColor: COLORS.pinkH,
        borderColor: COLORS.pinkL,
        borderWidth: 1,
        stack: "STACK 2",
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
      {
        type: "bar",
        label: "利用ポイント",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(d => d.guest?.spend || 0)
          : [],
        backgroundColor: COLORS.aquaH,
        borderColor: COLORS.aquaL,
        borderWidth: 1,
        stack: "STACK 2",
        datalabels: {
          align: "start",
          anchor: "start",
        },
      },
    ],
  }

  const pointYearlyData = {
    labels: yearlyPointsData.data
      ? yearlyPointsData.data.map(d =>
          d.dt ? moment(d.dt, "YYYY-MM-DD").format("MMM") : "",
        )
      : [],
    datasets: [
      {
        type: "bar",
        label: "ポイント購入数",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(d => d.guest?.buy || 0)
          : [],
        backgroundColor: COLORS.greenH,
        borderColor: COLORS.greenL,
        borderWidth: 1,
        stack: "STACK 1",
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
      {
        type: "bar",
        label: "報酬ポイント",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(
              d =>
                ((d.cast?.basic || 0) +
                  (d.cast?.extension || 0) +
                  (d.cast?.priority || 0) +
                  (d.cast?.night || 0)) *
                0.7,
            )
          : [],
        backgroundColor: COLORS.pinkH,
        borderColor: COLORS.pinkL,
        borderWidth: 1,
        stack: "STACK 2",
        datalabels: {
          anchor: "center",
          align: "center",
        },
      },
      {
        type: "bar",
        label: "利用ポイント",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(d => d.guest?.spend || 0)
          : [],
        backgroundColor: COLORS.aquaH,
        borderColor: COLORS.aquaL,
        borderWidth: 1,
        stack: "STACK 2",
        datalabels: {
          anchor: "center",
          align: "center",
        },
      },
    ],
  }

  const guestMatchingDailyData = {
    labels:
      dailyPointsData && dailyPointsData.totalCur
        ? [
            "基本料 - " +
              (dailyPointsData.totalCur.cast?.basic || "0").toLocaleString() +
              "P",
            "延長料 - " +
              (
                dailyPointsData.totalCur.cast?.extension || "0"
              ).toLocaleString() +
              "P",
            "セレクト料 - " +
              (
                dailyPointsData.totalCur.cast?.priority || "0"
              ).toLocaleString() +
              "P",
            "深夜料 - " +
              (dailyPointsData.totalCur.cast?.night || "0").toLocaleString() +
              "P",
          ]
        : [],
    datasets: [
      {
        data:
          dailyPointsData && dailyPointsData.totalCur
            ? [
                dailyPointsData.totalCur.cast?.basic || 0,
                dailyPointsData.totalCur.cast?.extension || 0,
                dailyPointsData.totalCur.cast?.priority || 0,
                dailyPointsData.totalCur.cast?.night || 0,
              ]
            : [],
        backgroundColor: [
          COLORS.orangeH,
          COLORS.yellowH,
          COLORS.redH,
          COLORS.purpleH,
        ],
        borderWidth: 1,
      },
    ],
  }

  const castMatchingDailyData = {
    labels:
      dailyPointsData && dailyPointsData.totalCur
        ? [
            "基本料 - " +
              (
                dailyPointsData.totalCur.cast?.basic * 0.7 || 0
              ).toLocaleString() +
              "P",
            "延長料 - " +
              (
                dailyPointsData.totalCur.cast?.extension * 0.7 || 0
              ).toLocaleString() +
              "P",
            "セレクト料 - " +
              (
                dailyPointsData.totalCur.cast?.priority * 0.7 || 0
              ).toLocaleString() +
              "P",
            "深夜料 - " +
              (
                dailyPointsData.totalCur.cast?.night * 0.7 || 0
              ).toLocaleString() +
              "P",
          ]
        : [],
    datasets: [
      {
        data:
          dailyPointsData && dailyPointsData.totalCur
            ? [
                dailyPointsData.totalCur.cast?.basic * 0.7 || 0,
                dailyPointsData.totalCur.cast?.extension * 0.7 || 0,
                dailyPointsData.totalCur.cast?.priority * 0.7 || 0,
                dailyPointsData.totalCur.cast?.night * 0.7 || 0,
              ]
            : [],
        backgroundColor: [
          COLORS.orangeH,
          COLORS.yellowH,
          COLORS.redH,
          COLORS.purpleH,
        ],
        borderWidth: 1,
      },
    ],
  }

  const guestMatchingWeeklyData = {
    labels: weeklyPointsData.data
      ? weeklyPointsData.data.map(d =>
          d.dt ? moment(d.dt, "YYYY-MM-DD").format("D(ddd)") : "",
        )
      : [],
    datasets: [
      {
        label: "全",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(
              d =>
                (d.cast?.basic || 0) +
                (d.cast?.extension || 0) +
                (d.cast?.priority || 0) +
                (d.cast?.night || 0),
            )
          : [],
        backgroundColor: COLORS.aquaH,
        borderColor: COLORS.aquaL,
        borderWidth: 1,
        datalabels: {
          offset: 10,
          anchor: "end",
          align: "start",
        },
        stack: "STACK 1",
      },
      {
        label: "基本料",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(d => d.cast?.basic || 0)
          : [],
        backgroundColor: COLORS.orangeH,
        borderColor: COLORS.orangeL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
      {
        label: "延長料",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(d => d.cast?.extension || 0)
          : [],
        backgroundColor: COLORS.yellowH,
        borderColor: COLORS.yellowL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
      {
        label: "セレクト料",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(d => d.cast?.priority || 0)
          : [],
        backgroundColor: COLORS.redH,
        borderColor: COLORS.redL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
      {
        label: "深夜料",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(d => d.cast?.night || 0)
          : [],
        backgroundColor: COLORS.purpleH,
        borderColor: COLORS.purpleL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
    ],
  }

  const castMatchingWeeklyData = {
    labels: weeklyPointsData.data
      ? weeklyPointsData.data.map(d =>
          d.dt ? moment(d.dt, "YYYY-MM-DD").format("D(ddd)") : "",
        )
      : [],
    datasets: [
      {
        label: "全",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(
              d =>
                ((d.cast?.basic || 0) +
                  (d.cast?.extension || 0) +
                  (d.cast?.priority || 0) +
                  (d.cast?.night || 0)) *
                0.7,
            )
          : [],
        backgroundColor: COLORS.pinkH,
        borderColor: COLORS.pinkL,
        borderWidth: 1,
        datalabels: {
          offset: 10,
          anchor: "end",
          align: "start",
        },
        stack: "STACK 1",
      },
      {
        label: "基本料",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(d => d.cast?.basic * 0.7 || 0)
          : [],
        backgroundColor: COLORS.orangeH,
        borderColor: COLORS.orangeL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
      {
        label: "延長料",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(d => d.cast?.extension * 0.7 || 0)
          : [],
        backgroundColor: COLORS.yellowH,
        borderColor: COLORS.yellowL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
      {
        label: "セレクト料",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(d => d.cast?.priority * 0.7 || 0)
          : [],
        backgroundColor: COLORS.redH,
        borderColor: COLORS.redL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
      {
        label: "深夜料",
        data: weeklyPointsData.data
          ? weeklyPointsData.data.map(d => d.cast?.night * 0.7 || 0)
          : [],
        backgroundColor: COLORS.purpleH,
        borderColor: COLORS.purpleL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
    ],
  }

  const guestMatchingMonthlyData = {
    labels: monthlyPointsData.data
      ? monthlyPointsData.data.map(d =>
          d.dt ? moment(d.dt, "YYYY-MM-DD").format("D") : "",
        )
      : [],
    datasets: [
      {
        label: "全",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(
              d =>
                (d.cast?.basic || 0) +
                (d.cast?.extension || 0) +
                (d.cast?.priority || 0) +
                (d.cast?.night || 0),
            )
          : [],
        backgroundColor: COLORS.aquaH,
        borderColor: COLORS.aquaL,
        borderWidth: 1,
        datalabels: {
          offset: 10,
          anchor: "end",
          align: "end",
          textStrokeColor: function(context) {
            return context.dataset.backgroundColor
          },
        },
        stack: "STACK 1",
      },
      {
        label: "基本料",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(d => d.cast?.basic || 0)
          : [],
        backgroundColor: COLORS.orangeH,
        borderColor: COLORS.orangeL,
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        stack: "STACK 2",
      },
      {
        label: "延長料",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(d => d.cast?.extension || 0)
          : [],
        backgroundColor: COLORS.yellowH,
        borderColor: COLORS.yellowL,
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        stack: "STACK 2",
      },
      {
        label: "セレクト料",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(d => d.cast?.priority || 0)
          : [],
        backgroundColor: COLORS.redH,
        borderColor: COLORS.redL,
        borderWidth: 1,
        stack: "STACK 2",
        datalabels: {
          display: false,
        },
      },
      {
        label: "深夜料",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(d => d.cast?.night || 0)
          : [],
        backgroundColor: COLORS.purpleH,
        borderColor: COLORS.purpleL,
        borderWidth: 1,
        stack: "STACK 2",
        datalabels: {
          display: false,
          // anchor: "end",
          // align: "end",
          // textStrokeColor: function(context) {
          //   return context.dataset.backgroundColor
          // },
          // formatter: (value, ctx) => {
          //   let index = ctx.dataIndex
          //   let text = ""

          //   if (monthlyPointsData.data[index]) {
          //     text +=
          //       (monthlyPointsData.data[index].cast?.basic || 0) !== 0
          //         ? (
          //             monthlyPointsData.data[index].cast?.basic || 0
          //           ).toLocaleString() +
          //           "P" +
          //           " | "
          //         : ""
          //     text +=
          //       (monthlyPointsData.data[index].cast?.basic || 0) !== 0
          //         ? (
          //             monthlyPointsData.data[index].cast?.extension || 0
          //           ).toLocaleString() +
          //           "P" +
          //           " | "
          //         : ""
          //     text +=
          //       (monthlyPointsData.data[index].cast?.basic || 0) !== 0
          //         ? (
          //             monthlyPointsData.data[index].cast?.priority || 0
          //           ).toLocaleString() +
          //           "P" +
          //           " | "
          //         : ""
          //     text +=
          //       (monthlyPointsData.data[index].cast?.basic || 0) !== 0
          //         ? (
          //             monthlyPointsData.data[index].cast?.night || 0
          //           ).toLocaleString() + "P"
          //         : ""
          //   }

          //   return text
          // },
        },
      },
    ],
  }

  const castMatchingMonthlyData = {
    labels: monthlyPointsData.data
      ? monthlyPointsData.data.map(d =>
          d.dt ? moment(d.dt, "YYYY-MM-DD").format("D") : "",
        )
      : [],
    datasets: [
      {
        label: "全",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(
              d =>
                ((d.cast?.basic || 0) +
                  (d.cast?.extension || 0) +
                  (d.cast?.priority || 0) +
                  (d.cast?.night || 0)) *
                0.7,
            )
          : [],
        backgroundColor: COLORS.pinkH,
        borderColor: COLORS.pinkL,
        borderWidth: 1,
        datalabels: {
          offset: 10,
          anchor: "end",
          align: "end",
          textStrokeColor: function(context) {
            return context.dataset.backgroundColor
          },
        },
        stack: "STACK 1",
      },
      {
        label: "基本料",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(d => d.cast?.basic * 0.7 || 0)
          : [],
        backgroundColor: COLORS.orangeH,
        borderColor: COLORS.orangeL,
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        stack: "STACK 2",
      },
      {
        label: "延長料",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(d => d.cast?.extension * 0.7 || 0)
          : [],
        backgroundColor: COLORS.yellowH,
        borderColor: COLORS.yellowL,
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        stack: "STACK 2",
      },
      {
        label: "セレクト料",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(d => d.cast?.priority * 0.7 || 0)
          : [],
        backgroundColor: COLORS.redH,
        borderColor: COLORS.redL,
        borderWidth: 1,
        stack: "STACK 2",
        datalabels: {
          display: false,
        },
      },
      {
        label: "深夜料",
        data: monthlyPointsData.data
          ? monthlyPointsData.data.map(d => d.cast?.night * 0.7 || 0)
          : [],
        backgroundColor: COLORS.purpleH,
        borderColor: COLORS.purpleL,
        borderWidth: 1,
        stack: "STACK 2",
        datalabels: {
          display: false,
          // anchor: "end",
          // align: "end",
          // textStrokeColor: function(context) {
          //   return context.dataset.backgroundColor
          // },
          // formatter: (value, ctx) => {
          //   let index = ctx.dataIndex
          //   let text = ""

          //   if (monthlyPointsData.data[index]) {
          //     text +=
          //       (monthlyPointsData.data[index].cast?.basic || 0) !== 0
          //         ? (
          //             monthlyPointsData.data[index].cast?.basic * 0.7 || 0
          //           ).toLocaleString() +
          //           "P" +
          //           " | "
          //         : ""
          //     text +=
          //       (monthlyPointsData.data[index].cast?.basic || 0) !== 0
          //         ? (
          //             monthlyPointsData.data[index].cast?.extension * 0.7 || 0
          //           ).toLocaleString() +
          //           "P" +
          //           " | "
          //         : ""
          //     text +=
          //       (monthlyPointsData.data[index].cast?.basic || 0) !== 0
          //         ? (
          //             monthlyPointsData.data[index].cast?.priority * 0.7 || 0
          //           ).toLocaleString() +
          //           "P" +
          //           " | "
          //         : ""
          //     text +=
          //       (monthlyPointsData.data[index].cast?.basic || 0) !== 0
          //         ? (
          //             monthlyPointsData.data[index].cast?.night * 0.7 || 0
          //           ).toLocaleString() + "P"
          //         : ""
          //   }

          //   return text
          // },
        },
      },
    ],
  }

  const guestMatchingYearlyData = {
    labels: yearlyPointsData.data
      ? yearlyPointsData.data.map(d =>
          d.dt ? moment(d.dt, "YYYY-MM-DD").format("MMM") : "",
        )
      : [],
    datasets: [
      {
        label: "全",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(
              d =>
                (d.cast?.basic || 0) +
                (d.cast?.extension || 0) +
                (d.cast?.priority || 0) +
                (d.cast?.night || 0),
            )
          : [],
        backgroundColor: COLORS.aquaH,
        borderColor: COLORS.aquaL,
        borderWidth: 1,
        datalabels: {
          anchor: "end",
          align: "start",
        },
        stack: "STACK 1",
      },
      {
        label: "基本料",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(d => d.cast?.basic || 0)
          : [],
        backgroundColor: COLORS.orangeH,
        borderColor: COLORS.orangeL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
      {
        label: "延長料",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(d => d.cast?.extension || 0)
          : [],
        backgroundColor: COLORS.yellowH,
        borderColor: COLORS.yellowL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
      {
        label: "セレクト料",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(d => d.cast?.priority || 0)
          : [],
        backgroundColor: COLORS.redH,
        borderColor: COLORS.redL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
      {
        label: "深夜料",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(d => d.cast?.night || 0)
          : [],
        backgroundColor: COLORS.purpleH,
        borderColor: COLORS.purpleL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
    ],
  }

  const castMatchingYearlyData = {
    labels: yearlyPointsData.data
      ? yearlyPointsData.data.map(d =>
          d.dt ? moment(d.dt, "YYYY-MM-DD").format("MMM") : "",
        )
      : [],
    datasets: [
      {
        label: "全",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(
              d =>
                ((d.cast?.basic || 0) +
                  (d.cast?.extension || 0) +
                  (d.cast?.priority || 0) +
                  (d.cast?.night || 0)) *
                0.7,
            )
          : [],
        backgroundColor: COLORS.pinkH,
        borderColor: COLORS.pinkL,
        borderWidth: 1,
        datalabels: {
          anchor: "end",
          align: "start",
        },
        stack: "STACK 1",
      },
      {
        label: "基本料",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(d => d.cast?.basic * 0.7 || 0)
          : [],
        backgroundColor: COLORS.orangeH,
        borderColor: COLORS.orangeL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
      {
        label: "延長料",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(d => d.cast?.extension * 0.7 || 0)
          : [],
        backgroundColor: COLORS.yellowH,
        borderColor: COLORS.yellowL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
      {
        label: "セレクト料",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(d => d.cast?.priority * 0.7 || 0)
          : [],
        backgroundColor: COLORS.redH,
        borderColor: COLORS.redL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
      {
        label: "深夜料",
        data: yearlyPointsData.data
          ? yearlyPointsData.data.map(d => d.cast?.night * 0.7 || 0)
          : [],
        backgroundColor: COLORS.purpleH,
        borderColor: COLORS.purpleL,
        borderWidth: 1,
        datalabels: {
          anchor: "center",
          align: "center",
        },
        stack: "STACK 2",
      },
    ],
  }

  const guestDailyRankingBarData = {
    labels: dailyRankingsData.guests
      ? dailyRankingsData.guests.map(guest => guest.name)
      : [],
    datasets: [
      {
        label: "ゲスト利用ポイント",
        data: dailyRankingsData.guests
          ? dailyRankingsData.guests.map(guest =>
              Math.abs(guest.sumPoints || 0),
            )
          : [],
        backgroundColor: COLORS.aquaH,
        borderColor: COLORS.aquaL,
        borderWidth: 1,
        barThickness: 100,
      },
    ],
  }

  const guestWeeklyRankingBarData = {
    labels: weeklyRankingsData.guests
      ? weeklyRankingsData.guests.map(guest => guest.name)
      : [],
    datasets: [
      {
        label: "ゲスト利用ポイント",
        data: weeklyRankingsData.guests
          ? weeklyRankingsData.guests.map(guest =>
              Math.abs(guest.sumPoints || 0),
            )
          : [],
        backgroundColor: COLORS.aquaH,
        borderColor: COLORS.aquaL,
        borderWidth: 1,
      },
    ],
  }

  const guestMonthlyRankingBarData = {
    labels: monthlyRankingsData.guests
      ? monthlyRankingsData.guests.map(guest => guest.name)
      : [],
    datasets: [
      {
        label: "ゲスト利用ポイント",
        data: monthlyRankingsData.guests
          ? monthlyRankingsData.guests.map(guest =>
              Math.abs(guest.sumPoints || 0),
            )
          : [],
        backgroundColor: COLORS.aquaH,
        borderColor: COLORS.aquaL,
        borderWidth: 1,
      },
    ],
  }

  const guestYearlyRankingBarData = {
    labels: yearlyRankingsData.guests
      ? yearlyRankingsData.guests.map(guest => guest.name)
      : [],
    datasets: [
      {
        label: "ゲスト利用ポイント",
        data: yearlyRankingsData.guests
          ? yearlyRankingsData.guests.map(guest =>
              Math.abs(guest.sumPoints || 0),
            )
          : [],
        backgroundColor: COLORS.aquaH,
        borderColor: COLORS.aquaL,
        borderWidth: 1,
      },
    ],
  }

  const castDailyRankingBarData = {
    labels: dailyRankingsData.cast
      ? dailyRankingsData.cast.map(c => c.name)
      : [],
    datasets: [
      {
        label: "キャスト報酬ポイント",
        data: dailyRankingsData.cast
          ? dailyRankingsData.cast.map(c => c.sumPoints * 0.7 || 0)
          : [],
        backgroundColor: COLORS.pinkH,
        borderColor: COLORS.pinkL,
        borderWidth: 1,
        barThickness: 100,
      },
    ],
  }

  const castWeeklyRankingBarData = {
    labels: weeklyRankingsData.cast
      ? weeklyRankingsData.cast.map(c => c.name)
      : [],
    datasets: [
      {
        label: "キャスト報酬ポイント",
        data: weeklyRankingsData.cast
          ? weeklyRankingsData.cast.map(c => c.sumPoints * 0.7 || 0)
          : [],
        backgroundColor: COLORS.pinkH,
        borderColor: COLORS.pinkL,
        borderWidth: 1,
      },
    ],
  }

  const castMonthlyRankingBarData = {
    labels: monthlyRankingsData.cast
      ? monthlyRankingsData.cast.map(c => c.name)
      : [],
    datasets: [
      {
        label: "キャスト報酬ポイント",
        data: monthlyRankingsData.cast
          ? monthlyRankingsData.cast.map(c => c.sumPoints * 0.7 || 0)
          : [],
        backgroundColor: COLORS.pinkH,
        borderColor: COLORS.pinkL,
        borderWidth: 1,
      },
    ],
  }

  const castYearlyRankingBarData = {
    labels: yearlyRankingsData.cast
      ? yearlyRankingsData.cast.map(c => c.name)
      : [],
    datasets: [
      {
        label: "キャスト報酬ポイント",
        data: yearlyRankingsData.cast
          ? yearlyRankingsData.cast.map(c => c.sumPoints * 0.7 || 0)
          : [],
        backgroundColor: COLORS.pinkH,
        borderColor: COLORS.pinkL,
        borderWidth: 1,
      },
    ],
  }

  const findGrowth = (current, prev) => {
    if (current - prev === 0 || prev === 0) return "0%"

    const result = Math.round(((current - prev) / prev + Number.EPSILON) * 100)

    return result > 0 ? "+" + result + "%" : result + "%"
  }

  const findTotalProfitPoint = (spend, castSum) => {
    return Math.abs(spend) - castSum
  }

  const findTotalProfitRate = (castSum, spend) => {
    if (castSum === 0 || spend === 0) return 0

    return Math.round(
      ((Math.abs(spend) - castSum) / Math.abs(spend) + Number.EPSILON) * 100,
    )
  }

  const findOneOnOne = (val, val2, noEpsilon) => {
    if (val === 0 || val2 === 0) return 0

    return noEpsilon
      ? Math.round(val / val2)
      : Math.round((val / val2 + Number.EPSILON) * 100) / 100
  }

  const prepareTableData = (obj, type, prevText = "", noSymbol = false) => {
    const castSum =
      ((obj.totalCur.cast?.basic || 0) +
        (obj.totalCur.cast?.extension || 0) +
        (obj.totalCur.cast?.priority || 0) +
        (obj.totalCur.cast?.night || 0)) *
      0.7

    if (type === "CURRENT") {
      return {
        key: obj.totalCur.dt,
        date: obj.totalCur.dt
          ? obj.totalCur.dt === "totalCur"
            ? "合計"
            : obj.totalCur.dt
          : "",
        totalUsage: (obj.totalCur.usage?.events || 0) + (noSymbol ? "" : "件"),
        totalUser: (obj.totalCur.usage?.users || 0) + (noSymbol ? "" : "人"),
        totalUsageTime:
          (obj.totalCur.usage?.hours || 0) + (noSymbol ? "" : "時間"),
        totalProfitPoint:
          findTotalProfitPoint(
            obj.totalCur.guest?.spend || 0,
            castSum,
          ).toLocaleString() + (noSymbol ? "" : "P"),
        totalProfitRate:
          findTotalProfitRate(castSum, obj.totalCur.guest?.spend || 0) + "%",
        matchUsagePoint:
          Math.abs(obj.totalCur.guest?.spend || 0).toLocaleString() +
          (noSymbol ? "" : "P"),
        matchRewardPoint: castSum.toLocaleString() + (noSymbol ? "" : "P"),
        matchPurchasePoint:
          (obj.totalCur.guest?.buy || 0).toLocaleString() +
          (noSymbol ? "" : "P"),
        matchGuestEarnPoint:
          (obj.totalCur.guest?.earn || 0).toLocaleString() +
          (noSymbol ? "" : "P"),
        matchCastEarnPoint:
          (obj.totalCur.cast?.earn || 0).toLocaleString() +
          (noSymbol ? "" : "P"),
        profitPoint: "0",
        guestPointBalance:
          (
            (obj.totalCur.usage?.buyTotal || 0) -
            Math.abs(obj.totalCur.usage?.spendTotalGuest || 0)
          ).toLocaleString() + (noSymbol ? "" : "P"),
        castPointBalance:
          (
            Math.abs(obj.totalCur.usage?.spendTotalCast * 0.7 || 0) -
            (obj.totalCur.usage?.yenTotal || 0)
          ).toLocaleString() + (noSymbol ? "" : "P"),
      }
    } else if (type === "PREV") {
      const prevCastSum =
        ((obj.totalPrev.cast?.basic || 0) +
          (obj.totalPrev.cast?.extension || 0) +
          (obj.totalPrev.cast?.priority || 0) +
          (obj.totalPrev.cast?.night || 0)) *
        0.7

      return {
        key: obj.totalPrev.dt || "",
        date: prevText,
        totalUsage: findGrowth(
          obj.totalCur.usage?.events || 0,
          obj.totalPrev.usage?.events || 0,
        ),
        totalUser: findGrowth(
          obj.totalCur.usage?.users || 0,
          obj.totalPrev.usage?.users || 0,
        ),
        totalUsageTime: findGrowth(
          obj.totalCur.usage?.hours || 0,
          obj.totalPrev.usage?.hours || 0,
        ),
        totalProfitPoint: findGrowth(
          findTotalProfitPoint(obj.totalCur.guest?.spend || 0, castSum),
          findTotalProfitPoint(obj.totalPrev.guest?.spend || 0, prevCastSum),
        ),
        totalProfitRate: "-",
        matchUsagePoint: findGrowth(
          Math.abs(obj.totalCur.guest?.spend || 0),
          Math.abs(obj.totalPrev.guest?.spend || 0),
        ),
        matchRewardPoint: findGrowth(castSum, prevCastSum),
        matchPurchasePoint: findGrowth(
          obj.totalCur.guest?.buy || 0,
          obj.totalPrev.guest?.buy || 0,
        ),
        matchGuestEarnPoint: findGrowth(
          obj.totalCur.guest?.earn || 0,
          obj.totalPrev.guest?.earn || 0,
        ),
        matchCastEarnPoint: findGrowth(
          obj.totalCur.cast?.earn || 0,
          obj.totalPrev.cast?.earn || 0,
        ),
        profitPoint: "0",
        guestPointBalance: findGrowth(
          (obj.totalCur.usage?.buyTotal || 0) -
            Math.abs(obj.totalCur.usage?.spendTotalGuest || 0),
          (obj.totalPrev.usage?.buyTotal || 0) -
            Math.abs(obj.totalPrev.usage?.spendTotalGuest || 0),
        ),
        castPointBalance: findGrowth(
          Math.abs(obj.totalCur.usage?.spendTotalCast * 0.7 || 0) -
            (obj.totalCur.usage?.yenTotal || 0),
          Math.abs(obj.totalPrev.usage?.spendTotalCast * 0.7 || 0) -
            (obj.totalPrev.usage?.yenTotal || 0),
        ),
      }
    } else if (type === "COMPARE1") {
      return {
        key: "1件あたり",
        date: "1件あたり",
        totalUsage: "-",
        totalUser:
          findOneOnOne(
            obj.totalCur.usage?.users || 0,
            obj.totalCur.usage?.events || 0,
            true,
          ) + (noSymbol ? "" : "人"),
        totalUsageTime:
          findOneOnOne(
            obj.totalCur.usage?.hours || 0,
            obj.totalCur.usage?.events || 0,
          ) + (noSymbol ? "" : "時間"),
        totalProfitPoint:
          findOneOnOne(
            findTotalProfitPoint(obj.totalCur.guest?.spend || 0, castSum),
            obj.totalCur.usage?.events || 0,
          ).toLocaleString() + (noSymbol ? "" : "P"),
        totalProfitRate: "-",
        matchUsagePoint: "-",
        matchRewardPoint: "-",
        matchPurchasePoint: "-",
        matchGuestEarnPoint: "-",
        matchCastEarnPoint: "-",
        profitPoint: "-",
        guestPointBalance: "-",
        castPointBalance: "-",
      }
    } else if (type === "COMPARE2") {
      return {
        key: "1人あたり",
        date: "1人あたり",
        totalUsage: "-",
        totalUser: "-",
        totalUsageTime:
          findOneOnOne(
            obj.totalCur.usage?.hours || 0,
            obj.totalCur.usage?.users || 0,
          ) + (noSymbol ? "" : "時間"),
        totalProfitPoint:
          findOneOnOne(
            findTotalProfitPoint(obj.totalCur.guest?.spend || 0, castSum),
            obj.totalCur.usage?.users || 0,
          ).toLocaleString() + (noSymbol ? "" : "P"),
        totalProfitRate: "-",
        matchUsagePoint: "-",
        matchRewardPoint: "-",
        matchPurchasePoint: "-",
        matchGuestEarnPoint: "-",
        matchCastEarnPoint: "-",
        profitPoint: "-",
        guestPointBalance: "-",
        castPointBalance: "-",
      }
    } else if (type === "AVERAGE") {
      return {
        key: "平均",
        date: "平均",
        totalUsage:
          findOneOnOne(obj.totalCur.usage?.events || 0, obj.data.length) +
          (noSymbol ? "" : "件"),
        totalUser:
          findOneOnOne(obj.totalCur.usage?.users || 0, obj.data.length) +
          (noSymbol ? "" : "人"),
        totalUsageTime:
          findOneOnOne(obj.totalCur.usage?.hours || 0, obj.data.length) +
          (noSymbol ? "" : "時間"),
        totalProfitPoint:
          findOneOnOne(
            findTotalProfitPoint(obj.totalCur.guest?.spend || 0, castSum),
            obj.data.length,
          ).toLocaleString() + (noSymbol ? "" : "P"),
        totalProfitRate:
          findOneOnOne(
            findTotalProfitRate(castSum, obj.totalCur.guest?.spend || 0),
            obj.data.length,
          ) + "%",
        matchUsagePoint:
          findOneOnOne(
            Math.abs(obj.totalCur.guest?.spend || 0),
            obj.data.length,
          ).toLocaleString() + (noSymbol ? "" : "P"),
        matchRewardPoint:
          findOneOnOne(castSum, obj.data.length).toLocaleString() +
          (noSymbol ? "" : "P"),
        matchPurchasePoint:
          findOneOnOne(
            obj.totalCur.guest?.buy || 0,
            obj.data.length,
          ).toLocaleString() + (noSymbol ? "" : "P"),
        matchGuestEarnPoint:
          findOneOnOne(
            obj.totalCur.guest?.earn || 0,
            obj.data.length,
          ).toLocaleString() + (noSymbol ? "" : "P"),
        matchCastEarnPoint:
          findOneOnOne(
            obj.totalCur.cast?.earn || 0,
            obj.data.length,
          ).toLocaleString() + (noSymbol ? "" : "P"),
        profitPoint: "0",
        guestPointBalance:
          findOneOnOne(
            (obj.totalCur.usage?.buyTotal || 0) -
              Math.abs(obj.totalCur.usage?.spendTotalGuest || 0),
            obj.data.length,
          ).toLocaleString() + (noSymbol ? "" : "P"),
        castPointBalance:
          findOneOnOne(
            Math.abs(obj.totalCur.usage?.spendTotalCast * 0.7 || 0) -
              (obj.totalCur.usage?.yenTotal || 0),
            obj.data.length,
          ).toLocaleString() + (noSymbol ? "" : "P"),
      }
    }
  }

  const cleanForm = e => {
    setSelectedDuration(e.target.value)
    form.setFieldsValue({
      durationFilter: e.target.value,
    })
  }

  const exportDailyPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4", true)

    pdf.addFileToVFS("Kosugi-Regular.ttf", KosugiRegular.font)
    pdf.addFont("Kosugi-Regular.ttf", "Kosugi-Regular", "normal")

    pdf.setFont("Kosugi-Regular")
    pdf.setFontSize(12)
    pdf.text("日次営業レポート", MARGIN + 80, MARGIN + 10)
    pdf.text(
      dailyPointsData.totalCur ? dailyPointsData.totalCur.dt : "",
      MARGIN + 169,
      MARGIN + 10,
    )
    pdf.line(MARGIN, MARGIN + 20, 200, MARGIN + 20)

    if (pdf) {
      let content = {
        startY: MARGIN + 30,
        margin: 10,
        theme: "grid",
        font: "Kosugi-Regular",
        putOnlyUsedFonts: true,
        headStyles: {
          valign: "middle",
          halign: "center",
          fillColor: "#3dbaeb",
          font: "Kosugi-Regular",
          fontSize: 7,
          lineWidth: 0.1,
          lineColor: [255, 255, 255],
        },
        bodyStyles: {
          valign: "middle",
          halign: "center",
          font: "Kosugi-Regular",
          fontSize: 7,
        },
        head: [
          [
            {
              content: "日付",
              colSpan: 1,
              rowSpan: 2,
            },
            {
              content: "合計",
              colSpan: 5,
              rowSpan: 1,
            },
            {
              content: "利益",
              colSpan: 3,
              rowSpan: 1,
            },
            {
              content: "その他\nご利用\nポイント",
              colSpan: 1,
              rowSpan: 2,
            },
            {
              content: "ゲスト\nポイント\n残高",
              colSpan: 1,
              rowSpan: 2,
            },
            {
              content: "キャスト\nポイント\n残高",
              colSpan: 1,
              rowSpan: 2,
            },
          ],
          [
            "利用\n件数",
            "利用\n人数",
            "利用\n時間",
            "利益\nポイント",
            "利益率",
            "利用\nポイント",
            "報酬\nポイント",
            "ポイント\n購入数",
          ],
        ],
        body:
          dailyPointsData.totalCur && dailyPointsData.totalPrev
            ? [
                Object.values(
                  prepareTableData(dailyPointsData, "CURRENT"),
                ).filter((e, i) => i > 0),
                Object.values(
                  prepareTableData(dailyPointsData, "COMPARE1"),
                ).filter((e, i) => i > 0),
                Object.values(
                  prepareTableData(dailyPointsData, "COMPARE2"),
                ).filter((e, i) => i > 0),
                Object.values(
                  prepareTableData(dailyPointsData, "PREV", "前日との差"),
                ).filter((e, i) => i > 0),
              ]
            : [],
      }

      pdf.autoTable(content)
      pdf.addImage(
        dailyPointChartRef.current.chartInstance.toBase64Image(),
        "JPEG",
        MARGIN + 50,
        MARGIN + 80,
        95,
        65,
        undefined,
        "FAST",
      )
      pdf.addImage(
        dailyGuestMatchingChartRef.current.chartInstance.toBase64Image(),
        "JPEG",
        MARGIN,
        MARGIN + 160,
        95,
        65,
        undefined,
        "FAST",
      )
      pdf.addImage(
        dailyCastMatchingChartRef.current.chartInstance.toBase64Image(),
        "JPEG",
        110,
        MARGIN + 160,
        95,
        65,
        undefined,
        "FAST",
      )
      pdf.addPage()
      pdf.addImage(
        dailyGuestRankingChartRef.current.chartInstance.toBase64Image(),
        "JPEG",
        MARGIN,
        MARGIN,
        190,
        95,
        undefined,
        "FAST",
      )
      pdf.addImage(
        dailyCastRankingChartRef.current.chartInstance.toBase64Image(),
        "JPEG",
        MARGIN,
        MARGIN + 120,
        190,
        95,
        undefined,
        "FAST",
      )
      pdf.save("日次営業レポート.pdf")

      handleDownloadLog()
    }
  }

  const showHistoryModal = () => {
    setIsHistoryModalVisible(true)
  }

  const hideHistoryModal = () => {
    setIsHistoryModalVisible(false)
  }

  return (
    <>
      <Card title="売上管理" bordered={false} className="h-full">
        <Card
          title="検索フィルタ"
          bordered={true}
          type="inner"
          className="h-full mb-5"
          headStyle={{
            color: "#FFF",
            backgroundColor: "#3dbaeb",
          }}
          style={{
            borderColor: "#3dbaeb",
          }}
        >
          <Form
            form={form}
            name="DateFilterForm"
            onFinish={handleFilter}
            scrollToFirstError
            colon={false}
            size="middle"
            initialValues={{
              durationFilter: FILTER_DURATION_TYPE[0].key,
              dateDurationFilter: moment(),
              weekDurationFilter: moment(),
              monthDurationFilter: moment(),
              yearDurationFilter: moment(),
            }}
          >
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <Form.Item name="durationFilter" style={{ marginBottom: 0 }}>
                  <Radio.Group buttonStyle="solid" onChange={e => cleanForm(e)}>
                    {FILTER_DURATION_TYPE.map(duration => (
                      <Radio.Button key={duration.key} value={duration.key}>
                        {duration.value}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Divider />
              <Col>
                {selectedDuration === FILTER_DURATION_TYPE[0].key ? (
                  <Form.Item
                    name="dateDurationFilter"
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "日を選択してください",
                      },
                    ]}
                  >
                    <DatePicker
                      inputReadOnly
                      placeholder="日を選択してください"
                    />
                  </Form.Item>
                ) : selectedDuration === FILTER_DURATION_TYPE[1].key ? (
                  <Form.Item
                    name="weekDurationFilter"
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "週を選択してください",
                      },
                    ]}
                  >
                    <DatePicker
                      inputReadOnly
                      placeholder="週を選択してください"
                      picker="week"
                    />
                  </Form.Item>
                ) : selectedDuration === FILTER_DURATION_TYPE[2].key ? (
                  <Form.Item
                    name="monthDurationFilter"
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "月を選択してください",
                      },
                    ]}
                  >
                    <DatePicker
                      inputReadOnly
                      placeholder="月を選択してください"
                      picker="month"
                    />
                  </Form.Item>
                ) : selectedDuration === FILTER_DURATION_TYPE[3].key ? (
                  <Form.Item
                    name="yearDurationFilter"
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "年を選択してください",
                      },
                    ]}
                  >
                    <DatePicker
                      inputReadOnly
                      placeholder="年を選択してください"
                      picker="year"
                    />
                  </Form.Item>
                ) : selectedDuration === FILTER_DURATION_TYPE[4].key ? (
                  <Form.Item
                    name="customDurationFilter"
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "期間を選択してください",
                      },
                    ]}
                  >
                    <RangePicker
                      inputReadOnly
                      ranges={{
                        今日: [moment(), moment()],
                        今月: [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                      }}
                    />
                  </Form.Item>
                ) : (
                  ""
                )}
              </Col>
              <Col>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SearchOutlined />}
                  >
                    検索
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        {selectedDuration === FILTER_DURATION_TYPE[0].key ? (
          <Card
            title="営業レポート"
            bordered={true}
            type="inner"
            headStyle={{
              color: "#FFF",
              backgroundColor: "#3dbaeb",
            }}
            style={{
              borderColor: "#3dbaeb",
            }}
            extra={
              <>
                <Button
                  className="m-1"
                  icon={<ContainerOutlined />}
                  onClick={showHistoryModal}
                >
                  履歴
                </Button>
                <Button
                  className="m-1"
                  icon={<FilePdfOutlined />}
                  onClick={exportDailyPDF}
                  // disabled={
                  //   dailyPointsData && dailyPointsData.totalCur ? false : true
                  // }
                >
                  PDF
                </Button>
              </>
            }
          >
            <Row gutter={[8, 16]}>
              <Col xs={24}>
                <CustomTable
                  columns={columns}
                  pagination={false}
                  dataSource={
                    dailyPointsData.totalCur && dailyPointsData.totalPrev
                      ? [
                          prepareTableData(dailyPointsData, "CURRENT"),
                          prepareTableData(dailyPointsData, "COMPARE1"),
                          prepareTableData(dailyPointsData, "COMPARE2"),
                          prepareTableData(
                            dailyPointsData,
                            "PREV",
                            "前日との差",
                          ),
                        ]
                      : []
                  }
                  footer={() => (
                    <Row justify="end">
                      <Col>
                        {dailyPointsData.totalCur &&
                        dailyPointsData.totalPrev ? (
                          <CSVLink
                            className="mx-1 bg-white border border-solid border-gray-300 px-3 py-1"
                            data={
                              dailyPointsData.totalCur &&
                              dailyPointsData.totalPrev
                                ? [
                                    CSV_HEADER,
                                    Object.values(
                                      prepareTableData(
                                        dailyPointsData,
                                        "CURRENT",
                                        "",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                    Object.values(
                                      prepareTableData(
                                        dailyPointsData,
                                        "COMPARE1",
                                        "",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                    Object.values(
                                      prepareTableData(
                                        dailyPointsData,
                                        "COMPARE2",
                                        "",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                    Object.values(
                                      prepareTableData(
                                        dailyPointsData,
                                        "PREV",
                                        "前日との差",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                  ]
                                : []
                            }
                            filename={
                              "日次営業レポート-" +
                              (dailyPointsData && dailyPointsData.totalCur
                                ? dailyPointsData.totalCur.dt
                                : "") +
                              ".csv"
                            }
                          >
                            <FileTextOutlined /> CSV
                          </CSVLink>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  )}
                  bordered
                  size="small"
                  scroll={{ x: true }}
                />
              </Col>
              <Col xs={24}>
                <Card title="利益ポイント" bordered={true} type="inner">
                  <div className="hidden">
                    <Doughnut
                      ref={dailyPointChartRef}
                      data={pointDailyData}
                      legend={pointLegend}
                      options={{
                        ...pointDailyOptions,
                        responsive: false,
                      }}
                      width={600}
                      height={400}
                    />
                  </div>
                  <Doughnut
                    data={pointDailyData}
                    legend={pointLegend}
                    options={pointDailyOptions}
                    width={600}
                    height={400}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={12}>
                <Card title="ゲスト利用ポイント" bordered={true} type="inner">
                  <div className="hidden">
                    <Doughnut
                      ref={dailyGuestMatchingChartRef}
                      data={guestMatchingDailyData}
                      legend={guestMatchingDailyLegend}
                      options={{
                        ...guestMatchingDoughnutOptions,
                        responsive: false,
                      }}
                      width={600}
                      height={400}
                    />
                  </div>
                  <Doughnut
                    data={guestMatchingDailyData}
                    legend={guestMatchingDailyLegend}
                    options={guestMatchingDoughnutOptions}
                    width={600}
                    height={400}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={12}>
                <Card title="キャスト報酬ポイント" bordered={true} type="inner">
                  <div className="hidden">
                    <Doughnut
                      ref={dailyCastMatchingChartRef}
                      data={castMatchingDailyData}
                      legend={castMatchingDailyLegend}
                      options={{
                        ...castMatchingDoughnutOptions,
                        responsive: false,
                      }}
                      width={600}
                      height={400}
                    />
                  </div>
                  <Doughnut
                    data={castMatchingDailyData}
                    legend={castMatchingDailyLegend}
                    options={castMatchingDoughnutOptions}
                    width={600}
                    height={400}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="ゲストランキング" bordered={true} type="inner">
                  <div className="hidden">
                    <Bar
                      ref={dailyGuestRankingChartRef}
                      plugins={[ChartDataLabels]}
                      data={guestDailyRankingBarData}
                      legend={guestRankingLegend}
                      options={{ ...guestRankingOptions, responsive: false }}
                      width={1200}
                      height={600}
                    />
                  </div>
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={guestDailyRankingBarData}
                    legend={guestRankingLegend}
                    options={guestRankingOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="キャストランキング" bordered={true} type="inner">
                  <div className="hidden">
                    <Bar
                      ref={dailyCastRankingChartRef}
                      plugins={[ChartDataLabels]}
                      data={castDailyRankingBarData}
                      legend={castRankingLegend}
                      options={{ ...castRankingOptions, responsive: false }}
                      width={1200}
                      height={600}
                    />
                  </div>
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={castDailyRankingBarData}
                    legend={castRankingLegend}
                    options={castRankingOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        ) : selectedDuration === FILTER_DURATION_TYPE[1].key ? (
          <Card
            title="営業レポート"
            bordered={true}
            type="inner"
            headStyle={{
              color: "#FFF",
              backgroundColor: "#3dbaeb",
            }}
            style={{
              borderColor: "#3dbaeb",
            }}
          >
            <Row gutter={[8, 16]}>
              <Col xs={24}>
                <CustomTable
                  columns={columns}
                  pagination={false}
                  dataSource={
                    weeklyPointsData.data &&
                    weeklyPointsData.totalCur &&
                    weeklyPointsData.totalPrev
                      ? [
                          ...weeklyPointsData.data.map(d =>
                            prepareTableData({ totalCur: d }, "CURRENT"),
                          ),
                          prepareTableData(weeklyPointsData, "CURRENT"),
                          prepareTableData(weeklyPointsData, "AVERAGE"),
                          prepareTableData(
                            weeklyPointsData,
                            "PREV",
                            "先週との差",
                          ),
                        ]
                      : []
                  }
                  footer={() => (
                    <Row justify="end">
                      <Col>
                        {weeklyPointsData.data &&
                        weeklyPointsData.totalCur &&
                        weeklyPointsData.totalPrev ? (
                          <CSVLink
                            className="mx-1 bg-white border border-solid border-gray-300 px-3 py-1"
                            data={
                              weeklyPointsData.data &&
                              weeklyPointsData.totalCur &&
                              weeklyPointsData.totalPrev
                                ? [
                                    CSV_HEADER,
                                    ...weeklyPointsData.data.map(d =>
                                      Object.values(
                                        prepareTableData(
                                          { totalCur: d },
                                          "CURRENT",
                                          "",
                                          true,
                                        ),
                                      ).filter((e, i) => i > 0),
                                    ),
                                    Object.values(
                                      prepareTableData(
                                        weeklyPointsData,
                                        "CURRENT",
                                        "",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                    Object.values(
                                      prepareTableData(
                                        weeklyPointsData,
                                        "AVERAGE",
                                        "",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                    Object.values(
                                      prepareTableData(
                                        weeklyPointsData,
                                        "PREV",
                                        "先週との差",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                  ]
                                : []
                            }
                            filename={
                              "週次営業レポート-" +
                              (weeklyPointsData.year && weeklyPointsData.week
                                ? weeklyPointsData.year +
                                  "年第" +
                                  weeklyPointsData.week +
                                  "週"
                                : "") +
                              ".csv"
                            }
                          >
                            <FileTextOutlined /> CSV
                          </CSVLink>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  )}
                  bordered
                  size="small"
                  scroll={{ x: true }}
                />
              </Col>
              <Col xs={24}>
                <Card title="利益ポイント" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={pointWeeklyData}
                    legend={pointLegend}
                    options={pointWeeklyOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="ゲスト利用ポイント" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={guestMatchingWeeklyData}
                    legend={guestMatchingLegend}
                    options={guestMatchingWeeklyBarOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="キャスト報酬ポイント" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={castMatchingWeeklyData}
                    legend={castMatchingLegend}
                    options={castMatchingWeeklyBarOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="ゲストランキング" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={guestWeeklyRankingBarData}
                    legend={guestRankingLegend}
                    options={guestRankingOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="キャストランキング" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={castWeeklyRankingBarData}
                    legend={castRankingLegend}
                    options={castRankingOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        ) : selectedDuration === FILTER_DURATION_TYPE[2].key ? (
          <Card
            title="営業レポート"
            bordered={true}
            type="inner"
            headStyle={{
              color: "#FFF",
              backgroundColor: "#3dbaeb",
            }}
            style={{
              borderColor: "#3dbaeb",
            }}
          >
            <Row gutter={[8, 16]}>
              <Col xs={24}>
                <CustomTable
                  columns={columns}
                  pagination={false}
                  dataSource={
                    monthlyPointsData.data &&
                    monthlyPointsData.totalCur &&
                    monthlyPointsData.totalPrev
                      ? [
                          ...monthlyPointsData.data.map(d =>
                            prepareTableData({ totalCur: d }, "CURRENT"),
                          ),
                          prepareTableData(monthlyPointsData, "CURRENT"),
                          prepareTableData(monthlyPointsData, "AVERAGE"),
                          prepareTableData(
                            monthlyPointsData,
                            "PREV",
                            "前月との差",
                          ),
                        ]
                      : []
                  }
                  footer={() => (
                    <Row justify="end">
                      <Col>
                        {monthlyPointsData.data &&
                        monthlyPointsData.totalCur &&
                        monthlyPointsData.totalPrev ? (
                          <CSVLink
                            className="mx-1 bg-white border border-solid border-gray-300 px-3 py-1"
                            data={
                              monthlyPointsData.data &&
                              monthlyPointsData.totalCur &&
                              monthlyPointsData.totalPrev
                                ? [
                                    CSV_HEADER,
                                    ...monthlyPointsData.data.map(d =>
                                      Object.values(
                                        prepareTableData(
                                          { totalCur: d },
                                          "CURRENT",
                                          "",
                                          true,
                                        ),
                                      ).filter((e, i) => i > 0),
                                    ),
                                    Object.values(
                                      prepareTableData(
                                        monthlyPointsData,
                                        "CURRENT",
                                        "",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                    Object.values(
                                      prepareTableData(
                                        monthlyPointsData,
                                        "AVERAGE",
                                        "",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                    Object.values(
                                      prepareTableData(
                                        monthlyPointsData,
                                        "PREV",
                                        "前月との差",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                  ]
                                : []
                            }
                            filename={
                              "月次営業レポート-" +
                              (monthlyPointsData.year && monthlyPointsData.month
                                ? monthlyPointsData.year +
                                  "年" +
                                  monthlyPointsData.month +
                                  "月"
                                : "") +
                              ".csv"
                            }
                          >
                            <FileTextOutlined /> CSV
                          </CSVLink>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  )}
                  bordered
                  size="small"
                  scroll={{ x: true }}
                />
              </Col>
              <Col xs={24}>
                <Card title="利益ポイント" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={pointMonthlyData}
                    legend={pointLegend}
                    options={pointMonthlyOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="ゲスト利用ポイント" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={guestMatchingMonthlyData}
                    legend={guestMatchingLegend}
                    options={guestMatchingMonthlyBarOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="キャスト報酬ポイント" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={castMatchingMonthlyData}
                    legend={castMatchingLegend}
                    options={castMatchingMonthlyBarOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="ゲストランキング" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={guestMonthlyRankingBarData}
                    legend={guestRankingLegend}
                    options={guestRankingOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="キャストランキング" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={castMonthlyRankingBarData}
                    legend={castRankingLegend}
                    options={castRankingOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        ) : selectedDuration === FILTER_DURATION_TYPE[3].key ? (
          <Card
            title="営業レポート"
            bordered={true}
            type="inner"
            headStyle={{
              color: "#FFF",
              backgroundColor: "#3dbaeb",
            }}
            style={{
              borderColor: "#3dbaeb",
            }}
          >
            <Row gutter={[8, 16]}>
              <Col xs={24}>
                <CustomTable
                  columns={columns}
                  pagination={false}
                  dataSource={
                    yearlyPointsData.data &&
                    yearlyPointsData.totalCur &&
                    yearlyPointsData.totalPrev
                      ? [
                          ...yearlyPointsData.data.map(d =>
                            prepareTableData({ totalCur: d }, "CURRENT"),
                          ),
                          prepareTableData(yearlyPointsData, "CURRENT"),
                          prepareTableData(yearlyPointsData, "AVERAGE"),
                          prepareTableData(
                            yearlyPointsData,
                            "PREV",
                            "前年との差",
                          ),
                        ]
                      : []
                  }
                  footer={() => (
                    <Row justify="end">
                      <Col>
                        {yearlyPointsData.data &&
                        yearlyPointsData.totalCur &&
                        yearlyPointsData.totalPrev ? (
                          <CSVLink
                            className="mx-1 bg-white border border-solid border-gray-300 px-3 py-1"
                            data={
                              yearlyPointsData.data &&
                              yearlyPointsData.totalCur &&
                              yearlyPointsData.totalPrev
                                ? [
                                    CSV_HEADER,
                                    ...yearlyPointsData.data.map(d =>
                                      Object.values(
                                        prepareTableData(
                                          { totalCur: d },
                                          "CURRENT",
                                          "",
                                          true,
                                        ),
                                      ).filter((e, i) => i > 0),
                                    ),
                                    Object.values(
                                      prepareTableData(
                                        yearlyPointsData,
                                        "CURRENT",
                                        "",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                    Object.values(
                                      prepareTableData(
                                        yearlyPointsData,
                                        "AVERAGE",
                                        "",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                    Object.values(
                                      prepareTableData(
                                        yearlyPointsData,
                                        "PREV",
                                        "前年との差",
                                        true,
                                      ),
                                    ).filter((e, i) => i > 0),
                                  ]
                                : []
                            }
                            filename={
                              "年次営業レポート-" +
                              (yearlyPointsData.year
                                ? yearlyPointsData.year + "年"
                                : "") +
                              ".csv"
                            }
                          >
                            <FileTextOutlined /> CSV
                          </CSVLink>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  )}
                  bordered
                  size="small"
                  scroll={{ x: true }}
                />
              </Col>
              <Col xs={24}>
                <Card title="利益ポイント" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={pointYearlyData}
                    legend={pointLegend}
                    options={pointWeeklyOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="ゲスト利用ポイント" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={guestMatchingYearlyData}
                    legend={guestMatchingLegend}
                    options={guestMatchingWeeklyBarOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="キャスト報酬ポイント" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={castMatchingYearlyData}
                    legend={castMatchingLegend}
                    options={castMatchingWeeklyBarOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="ゲストランキング" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={guestYearlyRankingBarData}
                    legend={guestRankingLegend}
                    options={guestRankingOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="キャストランキング" bordered={true} type="inner">
                  <Bar
                    plugins={[ChartDataLabels]}
                    data={castYearlyRankingBarData}
                    legend={castRankingLegend}
                    options={castRankingOptions}
                    width={1200}
                    height={600}
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        ) : (
          ""
        )}
      </Card>
      <Modal
        title="PDFダウンロード履歴"
        visible={isHistoryModalVisible}
        onCancel={hideHistoryModal}
        footer={null}
        centered
        zIndex={99999}
      >
        <Row justify="center" gutter={[0, 8]} className="m-1">
          <Col xs={24}>
            <CustomList
              header={<span className="text-white font-bold">日付</span>}
              bordered
              dataSource={downloadsData}
              renderItem={item => (
                <List.Item>
                  {moment(item).format("YYYY年MM月DD日 HH:mm")}　
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                </List.Item>
              )}
            />
          </Col>
          <Col xs={24}>
            <Alert
              message="※最後の10個のログのみが保存されます"
              type="warning"
              showIcon
            />
          </Col>
        </Row>
        <Row justify="center" className="mt-4">
          <Col>
            <Button onClick={hideHistoryModal}>閉じる</Button>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default withRouter(Home)
